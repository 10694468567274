import styled from "styled-components"
import React  from 'react'
import { Link } from "gatsby"



const Para = styled.div`
  margin-top: 0px;
  margin-bottom: 1px;
`

const  News = ({ text }) => {
  
    return(
          <div >
            <Para  className="container"><Link to="/Updates" className="Decor" dangerouslySetInnerHTML={{ __html: text }}/></Para>
            <hr  className="hrColor" />
          </div>
        )
}
export default News

