import { Link, graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import CarouselListing from "../components/homePage/carousel/CarouselListing"
import OfficeListing from "../components/Offices/OfficeListing"
import Content from "../components/Content"
import NewsListing from '../components/homePage/NewsBox/NewsListing'
import './index.css'
import Layout from "../components/Layout"
import BadgeListing from "../components/Badges/BadgeListing"




const Button = styled.button`
  padding: 10px 39px 15px 39px;
  background-color: #94040b;
  color: #ffffff;
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 50px;
  font-size: 20px;
  outline: none;
`

const IndexPage = ({ data }) => {
  return (

    
      <Layout>

        <CarouselListing />

        <div className="row">
          <div className="col-lg-8 col-md-10 mx-auto mr-lg-0">
            <div className="center TextStyle text-justify">
              <Content text={data.homeBlock.text.childContentfulRichText.html} />
            </div>

            <div className="container mx-auto learnMoreButton text-lg-left text-center">
              <Link to="/AboutUs" className="ButtonDecor col "><Button><div dangerouslySetInnerHTML={{ __html: data.homeBlock.buttonText }}/></Button></Link>
            </div>
            <BadgeListing data={data} />

          </div>

          <NewsListing />
        </div>
        <OfficeListing />

      </Layout>
    
  )
}


export default IndexPage

export const query = graphql`
    query HomePageQuery {
          homeBlock: contentfulPageTextAndHeadings(path: {eq: "/home"}) {
            buttonText
            text {
              childContentfulRichText {
                html
              }
            }
          }
          allContentfulBadges(filter: {slug: {eq: "Home"}}, sort: {fields: orderId}) {
            edges {
              node {
                orderId
                badge {
                  fluid(quality: 100) {
                    src
                  }
                }
                badgeLink
              }
            }
          }
    }
`


