import React from "react"
import styled from "styled-components"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./carousel.css"

const Carousel = ({ text, carouselClass, image, location }) => {

    const SlideDiv = styled.div`
      background-image: url(${props => props.image});
      width: 100%;
      height: 500px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;    
      `

    return (
        <SlideDiv className={carouselClass} image={image} data-interval="5000" >
          <div className="container">
            <div className="col">
              <div className="row center">
                <div className="sliderText" style={{
                  fontWeight: 400,
                  fontSize: '25px',
                  marginTop: 200,
                }} dangerouslySetInnerHTML={{__html: text}}>
                </div>
              </div>
            </div>
          </div>
          <div className="container mr-0">
            <div className="row">
              <div className="col sliderText text-right" style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                fontWeight: 400,
                // fontSize: '25px'
              }}><p className="fontScale">{location}</p></div>
            </div>
          </div>
        </SlideDiv>

    )
  }

  export default Carousel
