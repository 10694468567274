import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./carousel.css"
import Carousel from './Carousel'

const CarouselListing = () => {
  const data = useStaticQuery(graphql`
  query {
      allContentfulSlider(sort: {fields: orderId}) {
        edges {
          node {
            orderId
            active
            location
            text {
              childContentfulRichText {
                html
              }
            }
            image {
              fluid(quality:100) {
                src
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel" data-interval="5000">
      <ol className="carousel-indicators main-carousel">
        {
          data.allContentfulSlider.edges.map((edge) => {
            const classname = edge.node.active ? "active" : ""
            // console.log("order id", edge.node.orderId)
            return (
              <li data-target="#carouselExampleControls" data-slide-to={edge.node.orderId} key={edge.node.orderId} className={classname} />
            )
          })
        }
      </ol>
      <div className="carousel-inner">
        {
          data.allContentfulSlider.edges.map((edge) => {
            const classname = edge.node.active ? "active" : ""

            return (

              <Carousel
                key={edge.node.orderId}
                carouselClass={'carousel-item ' + classname}
                text={edge.node.text.childContentfulRichText.html}
                image={edge.node.image[0].fluid.src}
                location={edge.node.location}
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default CarouselListing
