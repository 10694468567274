import React  from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import News from './News'
import styled from "styled-components"




const NewsHead = styled.div`
  color: #ffffff;
  background-color: #94040b;
  padding: 1px;
  padding-left: 8px;
  font-size: 20px;
  font-family: "Rawline";
  font-weight:600; 
  margin-bottom: 15px;
`
const Scroll = styled.div`

&::scrollbar{
  width: 30px;
}
&::-scrollbar-track {
  background: #f1f1f1; 
}
`
const NewsListing = () => {

  const data = useStaticQuery(graphql`
  query {
      allContentfulNews(sort: {fields: contentfulid}) {
        edges {
          node {
            contentfulid
            text
          }
        }
      }
    }
  `)

        return (
            <div className="mx-auto NewsBox ml-lg-0"
          style={{
            backgroundColor: "#ffffff",
            width: "20%",
            height: "100%",
            display: "inline-block",
            padding: 15,
            marginTop:'2%',
            marginBottom: '3%'
          }}
        >
            <NewsHead>News</NewsHead>
            <Scroll style={{ overflow: "auto", height: 400 }}>
            {
                data.allContentfulNews.edges.map((edge) => {
                return (
                    <News key={edge.node.contentfulid} text={edge.node.text}/>
                        )
                  })

              }
            </Scroll>
        </div>
        
        )
    
}

export default NewsListing
